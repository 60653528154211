.drt-container {
  position: relative;
  height: calc(100vh - 64px);
  overflow: auto;
}
@media (max-width: 768px) {
  .drt-container .map {
    height: calc(60vh);
  }
}
/*# sourceMappingURL=src/pages/ondemand/drt/layout.css.map */