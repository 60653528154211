























































.drt-container
  position relative
  height calc(100vh - 64px)
  overflow auto
  .map
   @media (max-width 768px)
    height calc(60vh)
