







































































































































































































































@import '../../../styles/ondemand.styl'

.code
  background-image url('~assets/backgrounds/dialM.jpg')

.q-page
  display flex
  flex-direction column
  align-items center

.q-card
  margin 0
  @media (min-width 700px)
    margin-top -56px

.drt-form-text
  max-width 600px
  padding 20px 20px 0px

form
  max-width 600px
  display grid
  grid-template-columns 1fr 1fr
  grid-column-gap 15px
  grid-row-gap 5px
  grid-template-rows auto
  grid-template-areas ". ." \
    "forename-input surname-input" \
    ". ." \
    "email-input phone-input" \
    ". ." \
    ". ." \
    "wheelchair mobility-assistance" \
    "consent consent" \
    "tnc tnc" \
    "privacy privacy" \
    "submit submit"
  padding 20px
  @media (max-width 700px)
    display flex
    flex-direction column
    width 100vw

.inner-grid
  display grid
  grid-template-columns 25px auto
  grid-template-rows auto
  grid-template-areas ". ." \ "label label" \ "type type"

.tnc
  grid-area tnc
  display flex

.privacy
  grid-area privacy
  display flex

#forename
  grid-area forename-input

#surname
  grid-area surname-input

#email
  grid-area email-input
  margin-top 5px

#submit
  grid-area submit
  border none
  border-radius 25px
  padding 8px 16px

#mobility-type, #type-of-assistance
  grid-area type

.consent
  grid-area consent
  font-size 80%

input
  background-color rgba(0, 0, 0, 0)
  @media (min-width 700px)
    padding 5px 0
    border-bottom solid rgba(0,0,0,0.2) 1px
    transition border-bottom .36s
    transition-timing-function cubic-bezier(0.4, 0, 0.2, 1)
    &:hover
      border-bottom solid rgba(0,0,0,0.54) 1px
    &:focus
      border-bottom solid convert(brandColor)
  &[type="checkbox"]
    appearance checkbox
    margin 5px

.phone
  display flex
  flex-direction row
  grid-area phone-input
  @media (max-width 700px)
    margin-bottom -7px
    margin-top -9px

textarea
  padding 5px
  border solid rgba(0,0,0,0.2) 1px

label
  display flex
  flex-direction column
  font-size 75%
  letter-spacing 0.00937em
  margin 5px 0
  color rgba(0,0,0,0.6)
  &.q-select
    font-size 100%
    margin 0
    padding 0
    border none
  @media (max-width 700px)
    border solid rgba(0,0,0,0.2) 1px
    border-radius 25px
    padding 8px 16px
    &.q-select
      font-size 120%

select
  appearance none
  color rgba(0,0,0,0.6)
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%)
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat
  background-color white
  padding 8px 16px
  border solid rgba(0,0,0,0.2) 1px
  border-radius 25px

.checkbox-label
  border none
  padding 0

.mb-type-label
  grid-area label
  border none
  padding 0

.date-field
  padding-top 0
  padding-bottom 2px
  font-size 100%
