






































































































































































































































.shotl-form
  bottom 0
  right 35%
  width 30vw
  position absolute
  overflow hidden
  z-index 1
  padding 10px 10px 0
  @media (max-width: 768px) {
    width 100vw
    right 0
    left 0
    margin 0
  }

.q-field--with-bottom
  padding-bottom 0

.choose-route
  display grid
  grid-template-columns 36px auto 30px
  grid-column-gap 6px
  grid-template-rows auto
  align-items center

.swap
  grid-column-start 3
  grid-row-start 1
  grid-row-end 3
  color convert(brandColor)
  background white
  border none

.drt-button
  background white
  padding 6px
  border none
  display flex
  flex-direction column
  justify-content center
  align-items center

.set-origin
  color blue
  &.origin
    background tint(convert(brandColor), 80%)
    box-shadow inset 0px 0px 5px convert(brandColor)
    outline none

.set-destination
  color orange
  &.destination
    background tint(convert(brandColor), 80%)
    box-shadow inset 0px 0px 5px convert(brandColor)
    outline none

.confirm
  background convert(brandColor)
  color white
  font-size 20px
  width 100%
  grid-column-start 1
  grid-column-end 4
  border-radius 3px
  margin-bottom 24px
  line-height 1.715em
  padding 4px
  font-weight 500
  height 50px

.timetable
  width 100%

#info
  text-align right
  border none
  background white
  width 100%
  margin-bottom 10px
  padding-right 10px
  color black
  strong
    color convert(brandColor)

.back-button
  border none
  background white
  margin-bottom 10px
  border-radius 5px
