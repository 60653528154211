.mobile .code {
  display: none;
}
.mobile .column.container {
  margin-top: -32px;
}
.mobile .cta {
  background-color: #fafafa;
  padding: 16px 8px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 5px 8px rgba(0,0,0,0.14), 0 1px 14px rgba(0,0,0,0.12);
}
.cta {
  padding: 8px;
}
.code {
  height: 25vh;
  width: 100%;
  font-size: 8vmax;
  color: rgba(255,255,255,0.7);
  overflow: hidden;
  background-image: url("~assets/backgrounds/bike.jpg");
  background-position: 0% 67%;
  background-repeat: no-repeat;
  background-size: 100%;
  text-overflow: clip;
}
.column.container {
  margin-top: -64px;
}
.layout-padding {
  max-width: 768px;
}
.q-card {
  width: 80vw;
  max-width: 600px;
}
@media screen and (max-width: 700px) {
  .q-card {
    width: 100vw;
  }
}
.q-card.summary {
  max-width: 100%;
}
.center {
  justify-content: center;
  display: flex;
}
.page {
  padding-top: 16px;
}
.q-banner {
  border-width: 1px;
  border-style: solid;
  margin: 15px 0;
}
.q-btn {
  margin: 15px 0;
  width: 100%;
}
.status-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 125%;
  text-align: center;
}
.status-details {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 5px;
  grid-template-columns: 125px auto;
  align-items: center;
}
.status-icon {
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 90%;
  width: 100%;
}
.bike-hire-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.bike-hire-card.results-card {
  grid-template-rows: 2fr repeat(2, 1fr);
  grid-template-areas: "logo bike-pic"   "loc-name bike-pic"   "loc-addr price";
  height: 200px;
  overflow: hidden;
}
.bike-hire-card.summary-card {
  grid-template-auto-rows: auto;
  grid-template-areas: "title title"   "logo bike-pic"   "loc-name bike-pic"   "loc-addr price";
}
.title {
  grid-area: title;
}
.bike-hire-logo {
  grid-area: logo;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
}
.bike-hire-loc-name {
  grid-area: loc-name;
}
.bike-hire-loc-name.loc-large {
  font-weight: 600;
  font-size: 120%;
}
.bike-hire-loc-addr {
  grid-area: loc-addr;
}
.bike-hire-loc-date {
  grid-area: loc-addr;
  align-self: start;
}
.bike-hire-pic {
  grid-area: bike-pic;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}
.bike-hire-price {
  grid-area: price;
  margin-left: 35%;
}
.bike-hire-price.summary {
  align-self: start;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 5px;
  grid-template-rows: repeat(3, 1fr);
}
.code {
  background-image: url("~assets/backgrounds/dialM.jpg");
}
.q-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.q-card {
  margin: 0;
}
@media (min-width: 700px) {
  .q-card {
    margin-top: -56px;
  }
}
.drt-form-text {
  max-width: 600px;
  padding: 20px 20px 0px;
}
form {
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 5px;
  grid-template-rows: auto;
  grid-template-areas: ". ." "forename-input surname-input" ". ." "email-input phone-input" ". ." ". ." "wheelchair mobility-assistance" "consent consent" "tnc tnc" "privacy privacy" "submit submit";
  padding: 20px;
}
@media (max-width: 700px) {
  form {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
}
.inner-grid {
  display: grid;
  grid-template-columns: 25px auto;
  grid-template-rows: auto;
  grid-template-areas: ". ."   "label label"   "type type";
}
.tnc {
  grid-area: tnc;
  display: flex;
}
.privacy {
  grid-area: privacy;
  display: flex;
}
#forename {
  grid-area: forename-input;
}
#surname {
  grid-area: surname-input;
}
#email {
  grid-area: email-input;
  margin-top: 5px;
}
#submit {
  grid-area: submit;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
}
#mobility-type,
#type-of-assistance {
  grid-area: type;
}
.consent {
  grid-area: consent;
  font-size: 80%;
}
input {
  background-color: rgba(0,0,0,0);
}
@media (min-width: 700px) {
  input {
    padding: 5px 0;
    border-bottom: solid rgba(0,0,0,0.2) 1px;
    transition: border-bottom 0.36s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  input:hover {
    border-bottom: solid rgba(0,0,0,0.54) 1px;
  }
  input:focus {
    border-bottom: solid #406e8e;
  }
}
input[type="checkbox"] {
  appearance: checkbox;
  margin: 5px;
}
.phone {
  display: flex;
  flex-direction: row;
  grid-area: phone-input;
}
@media (max-width: 700px) {
  .phone {
    margin-bottom: -7px;
    margin-top: -9px;
  }
}
textarea {
  padding: 5px;
  border: solid rgba(0,0,0,0.2) 1px;
}
label {
  display: flex;
  flex-direction: column;
  font-size: 75%;
  letter-spacing: 0.00937em;
  margin: 5px 0;
  color: rgba(0,0,0,0.6);
}
label.q-select {
  font-size: 100%;
  margin: 0;
  padding: 0;
  border: none;
}
@media (max-width: 700px) {
  label {
    border: solid rgba(0,0,0,0.2) 1px;
    border-radius: 25px;
    padding: 8px 16px;
  }
  label.q-select {
    font-size: 120%;
  }
}
select {
  appearance: none;
  color: rgba(0,0,0,0.6);
  background-image: linear-gradient(45deg, transparent 50%, #808080 50%), linear-gradient(135deg, #808080 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  background-color: #fff;
  padding: 8px 16px;
  border: solid rgba(0,0,0,0.2) 1px;
  border-radius: 25px;
}
.checkbox-label {
  border: none;
  padding: 0;
}
.mb-type-label {
  grid-area: label;
  border: none;
  padding: 0;
}
.date-field {
  padding-top: 0;
  padding-bottom: 2px;
  font-size: 100%;
}
/*# sourceMappingURL=src/pages/ondemand/drt/register.css.map */