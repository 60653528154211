.route-map {
  height: calc(100vh - 64px);
  width: 100%;
  transition: height 0.3s;
}
@media (max-width: 768px) {
  .route-map {
    height: 60vh;
  }
}
/*# sourceMappingURL=src/pages/ondemand/drt/map.css.map */